import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@/components/Link'
import { Img } from '@/components/Img'
import { PlaceholderImage } from '@/components/PlaceholderImage'
import * as styles from './BlogPostThumb.module.scss'

const BlogPostThumb = ({ title, url, image, date }) => {
  return (
    <div className={styles.BlogPostThumb}>
      <div className={styles.image}>
        <span className={styles.image__inner}>
          <span className={styles.image__hover}>
            <span className={styles.image__filter}>
              <Link to={url}>
                <>
                  {image && (image.srcset || image.src) ? (
                    <Img {...image} />
                  ) : (
                    <PlaceholderImage />
                  )}
                </>
              </Link>
            </span>
          </span>
        </span>

        <span className={styles.image__overlay} />
      </div>
      <div className={styles.text}>
        <Link to={url}>
          {date && <>{date} — </>}
          {title}
        </Link>
      </div>
    </div>
  )
}

BlogPostThumb.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.object,
  date: PropTypes.string,
}

export { BlogPostThumb }
