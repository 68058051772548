import React from 'react'
import LogoSVG from '@/images/logo-oculus.inline.svg'
import * as styles from './PlaceholderImage.module.scss'

const PlaceholderImage = () => {
  return (
    <div className={styles.PlaceholderImage}>
      <div className={styles.inner}>
        <LogoSVG />
      </div>
    </div>
  )
}

export { PlaceholderImage }
