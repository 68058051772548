import { useStaticQuery, graphql } from 'gatsby'
import { linkResolver } from '@/prismic/helpers/linkResolver'
import { getImage } from '@/prismic/helpers/getImage'

export const useBlogPosts = () => {
  const { allPrismicBlogPost } = useStaticQuery(
    graphql`
      query GetBlogPosts {
        allPrismicBlogPost(sort: { fields: data___date, order: DESC }) {
          edges {
            node {
              id
              uid
              type
              data {
                title
                date(formatString: "DD MMM YYYY", locale: "en-GB")
                image {
                  fixed(width: 500) {
                    ...GatsbyPrismicImageFixed_noBase64
                  }
                  url
                  alt
                  dimensions {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return allPrismicBlogPost.edges.map(({ node }) => {
    return {
      title: node?.data?.title,
      id: node.uid,
      route: linkResolver(node),
      image: getImage(node.data?.image),
      date: node.data.date,
    }
  })
}
