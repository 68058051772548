import React from 'react'
import PropTypes from 'prop-types'
import { BlogPostsGrid } from '@/blocks/BlogPostsGrid'
import { Pagination } from '@/components/Pagination'
import * as styles from './BlogPosts.module.scss'

const BlogPosts = ({ posts, nextPage, prevPage }) => {
  return (
    <div className={styles.BlogPosts}>
      <div className={styles.container}>
        <BlogPostsGrid
          posts={posts}
          background="transparent"
          scrollEnabled={false}
        />
        {(prevPage || nextPage) && (
          <Pagination prevPage={prevPage} nextPage={nextPage} />
        )}
      </div>
    </div>
  )
}

BlogPosts.defaultProps = {
  posts: [],
}

BlogPosts.propTypes = {
  posts: PropTypes.array,
  nextPage: PropTypes.string,
  prevPage: PropTypes.string,
}

export default BlogPosts
