import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './ArrowIcon.module.scss'

// ArrowIcon
// size is the height and should be an even number in px
// all dimensions are em based on the size number
// the arrow aspect ratio can be adjusted per project in arrow.module.scss
// individual classes are added to allow for custom css transitions

const ArrowIcon = ({
  className,
  barClassName,
  endTopClassName,
  endBottomClassName,
  direction,
  size,
}) => (
  <span
    className={classNames(styles.ArrowIcon, className)}
    style={{ fontSize: size }}
    data-direction={direction}
  >
    <span className={classNames(styles.bar, barClassName)} />
    <span className={classNames(styles.endTop, endTopClassName)} />
    <span className={classNames(styles.endBottom, endBottomClassName)} />
  </span>
)

ArrowIcon.defaultProps = {
  direction: 'right',
}

ArrowIcon.propTypes = {
  className: PropTypes.string,
  barClassName: PropTypes.string,
  endTopClassName: PropTypes.string,
  endBottomClassName: PropTypes.string,
  size: PropTypes.string,
  direction: PropTypes.oneOf(['right', 'left']),
}

export { ArrowIcon }
