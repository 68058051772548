import React from 'react'
import PropTypes from 'prop-types'
import { Grid, GridRow, GridColumn } from '@/components/Grid'
import { BlogPostThumb } from '@/components/BlogPostThumb'
import { Container } from '@/components/Container'
import { Block } from '@/components/Block'
import * as styles from './BlogPostsGrid.module.scss'

const BlogPostsGrid = ({ posts, heading, background, scrollEnabled }) => {
  return (
    <Block
      className={styles.BlogPostsGrid}
      background={background}
      scrollEnabled={scrollEnabled}
    >
      {heading && (
        <Container>
          <h3 className={styles.heading}>{heading}</h3>
        </Container>
      )}
      <Grid>
        <GridRow>
          {posts.map((post) => (
            <GridColumn key={post.id} width="4" className={styles.column}>
              <BlogPostThumb
                title={post.title}
                url={post.route}
                image={post.image}
                date={post.date}
              />
            </GridColumn>
          ))}
        </GridRow>
      </Grid>
    </Block>
  )
}

BlogPostsGrid.defaultProps = {
  posts: [],
  background: '',
  scrollEnabled: true,
}

BlogPostsGrid.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.PropTypes.object),
  heading: PropTypes.string,
  background: PropTypes.string,
  scrollEnabled: PropTypes.bool,
}

export { BlogPostsGrid }
