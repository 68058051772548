import React from 'react'
import PropTypes from 'prop-types'
import { useBlogPosts } from '@/prismic/hooks/useBlogPosts'
import { linkResolver } from '@/prismic/helpers/linkResolver'
import BlogPosts from '@/templates/BlogPosts'

const BlogPostsPrismic = ({ pageContext }) => {
  const posts = useBlogPosts()
  const { pageNumber, numPages, uid, type, skip, limit } = pageContext

  return (
    <BlogPosts
      posts={posts.slice(skip, skip + limit)}
      nextPage={
        pageNumber < numPages
          ? linkResolver({ uid, type, pageNumber: pageNumber + 1 })
          : null
      }
      prevPage={
        pageNumber > 1
          ? linkResolver({ uid, type, pageNumber: pageNumber - 1 })
          : null
      }
    />
  )
}

BlogPostsPrismic.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default BlogPostsPrismic
